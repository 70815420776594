import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import FeaturesSection from "./../components/FeaturesSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="/images/header.jpg"
        bgImageOpacity={0.4}
        title={<>Focus on the <strong>games</strong>, not the <strong>admin</strong></>}
        subtitle="Competition scheduling without the headache"
        buttonText="Get started 💋"
        buttonColor="secondary"
        buttonPath="/dashboard"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
    </>
  );
}

export default IndexPage;
