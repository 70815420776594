import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Tournaments from "./Tournaments";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  warning: {
    color: colors.red["600"],
  }
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  function freePeriodRemaining() {
    const currentTime = Date.now();
    const differenceInMilliseconds = currentTime - auth.user.metadata.createdAt;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.max(0,Math.ceil(7 - differenceInDays))
  }

  function Greeting() {
    if (false && !auth.user.emailVerified) {
      return (
        <Card>
          <CardContent className={classes.cardContent} >
            <Box textAlign="center">
              <Typography paragraph={true} className={classes.warning}>
                Please check your inbox and click the link to confirm your email address!
                &nbsp;<Link to="/dashboard" onClick={() => window.location.reload()} style={{ color: '#A4A4A4' }}>Refresh</Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )
    } else {
      if (false && !auth.user.planIsActive && freePeriodRemaining() <= 0) {
        return (
          <Card>
            <CardContent className={classes.cardContent} >
              <Box textAlign="center">
                <Typography paragraph={true}>
                  Free period expired. You can signup for a plan in{" "}
                  <LinkMui component={Link} to="/pricing">
                    <strong>pricing</strong>.
                  </LinkMui>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        )
      } else {
        return (
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} md={6}>   
              <Tournaments />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box>
                    <Typography variant="h6" paragraph={true}>
                      <strong>Welcome{ auth.user?.name ? ", " + auth.user.name : "" }</strong>
                    </Typography>
                    <Typography paragraph={true}>
                      This is your tournament management portal.
                    </Typography>
                    <Typography paragraph={true}>
                      Create new tournaments by clicking on the Create button. After configuring a tournament you can click 
                      on it to view the draw and manage scoring.
                    </Typography>
                    <Typography paragraph={true}>
                      If you would like to see new functionality added, please don't hesitate to 
                      <LinkMui component={Link} to="/contact">
                            &nbsp;get in touch!
                      </LinkMui>
                    </Typography>
                    <Box mt={3}>
                      {/* <Typography variant="h6" paragraph={true}>
                        <strong>Extra debug info</strong>
                      </Typography> */}
                      <Typography component="div">
                        {/* <div>
                          You are signed in as <strong>{auth.user.email}</strong>.
                        </div> */}

                        {auth.user.stripeSubscriptionId && (
                          <>
                            <div>
                              You are subscribed to the{" "}
                              <strong>{auth.user.planId} plan</strong> 💕
                            </div>
                            {/* <div>
                              Your plan status is{" "}
                              <strong>
                                {auth.user.stripeSubscriptionStatus}
                              </strong>
                              .
                            </div> */}
                          </>
                        )}

                        {/* <div>
                          You can change your account info{` `}
                          {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                          in{` `}
                          <LinkMui component={Link} to="/settings/general">
                            <strong>settings</strong>
                          </LinkMui>
                          .
                        </div> */}

                        {false && !auth.user.stripeSubscriptionId && (
                          <div>
                            You have <strong>{freePeriodRemaining()} days left</strong> in your free trial. You can signup for a plan in{" "}
                            <LinkMui component={Link} to="/pricing">
                              pricing
                            </LinkMui>
                            .
                          </div>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )
      }
    } 
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}

        <Greeting />

      </Container>
    </Section>
  );
}

export default DashboardSection;
