import React from "react";
import Meta from "../components/Meta";
import { useParams } from "../util/router";
import { requireAuth } from "../util/auth";
import Section from "../components/Section";
import {
  Box,
  Card,
  Container,
  CircularProgress,
  InputLabel,
} from "@material-ui/core";
import SectionHeader from "../components/SectionHeader";
import { useItem } from "../util/db";
import DrawTable from "../components/DrawTable";
import { Tabs, Tab } from "@material-ui/core";
import { useState } from "react";
import { updateItem } from "../util/db";
import { Alert } from "@material-ui/lab";
import ScoreTab from "../components/ScoreTab";
import StatisticsTab from "../components/StatisticsTab";

function TournamentPage(props) {
  const { id: itemId } = useParams();
  const { data: itemData, status: itemStatus } = useItem(itemId);
  const [tabIndex, setTabIndex] = useState(0);
  const [formAlert, setFormAlert] = useState(null);

  //console.log("rendered tournament page")

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const skipNameUpdated = (team, name) => {
    updateItem(itemId, {
      [`teamInfo.${team}`]: name,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  };

  const endsScoreUpdated = (team, round, value) => {
    updateItem(itemId, {
      [`scores.round-${round}.team-${team}.ends-won`]: value,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  const homeScoreUpdated = (game, round, value) => {
    updateItem(itemId, {
      [`scores.round-${round}.game-${game}.shots-home`]: value,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  const awayScoreUpdated = (game, round, value) => {
    updateItem(itemId, {
      [`scores.round-${round}.game-${game}.shots-away`]: value,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  const pointsWinUpdated = (points) => {
    updateItem(itemId, {
      [`points.win`]: points,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  const pointsDrawUpdated = (points) => {
    updateItem(itemId, {
      [`points.draw`]: points,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  const pointsLossUpdated = (points) => {
    updateItem(itemId, {
      [`points.loss`]: points,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  const pointsEndsUpdated = (points) => {
    updateItem(itemId, {
      [`points.ends`]: points,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  const scoringMethodUpdated = (method) => {
    updateItem(itemId, {
      [`scoringMethod`]: method,
    }).catch((error) => {
      setFormAlert({
        type: "error",
        message: error.message,
      });
    });
  }

  return (
    <>
      <Meta title="View tournament" />
      <Section bgColor="default" size="medium" bgImage="" bgImageOpacity={1}>
        <Container>
          {itemStatus === "loading" && (
            <Box py={5} px={3} align="center">
              <CircularProgress size={32} />
            </Box>
          )}

          {itemStatus !== "loading" && itemData && (
            <>
              <SectionHeader
                title={itemData.name}
                size={4}
                textAlign="center"
              />
              <Box sx={{ paddingBottom: 40 }}>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                  <Tab label="Draw" />
                  <Tab label="Scores" />
                  <Tab label="Statistics" />
                </Tabs>
              </Box>

              {tabIndex === 0 && (
                <Box
                  display="flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Card>
                    <DrawTable
                      numTeams={itemData.teams}
                      rounds={itemData.rounds}
                      showScores={false}
                      skipNameUpdated={skipNameUpdated}
                      teamInfo={itemData.teamInfo}
                      scores={itemData.scores}
                      rankDataUpdated={ function() {} }
                    />
                  </Card>
                </Box>
              )}
              {tabIndex === 1 && (
                <ScoreTab
                  skipNameUpdated={skipNameUpdated}
                  endsScoreUpdated={endsScoreUpdated}
                  homeScoreUpdated={homeScoreUpdated}
                  awayScoreUpdated={awayScoreUpdated}
                  pointsWinChanged={pointsWinUpdated}
                  pointsDrawChanged={pointsDrawUpdated}
                  pointsLossChanged={pointsLossUpdated}
                  pointsEndsChanged={pointsEndsUpdated}
                  scoringMethodUpdated={scoringMethodUpdated}
                  itemData={itemData}
                />
              )}
              {tabIndex === 2 && <StatisticsTab itemData={itemData} />}
            </>
          )}
        </Container>
      </Section>
      {formAlert && (
        <Box mb={4}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
    </>
  );
}

export default requireAuth(TournamentPage);
