import React, { useReducer } from "react";
import { useState } from "react";
import { defineCustomElements } from "@revolist/revogrid/loader"; // webcomponent definition loader 
import { RevoGrid } from "@revolist/react-datagrid";
import "../revogrid.css"
import { useEffect } from "react";
import NumberColumnType from '@revolist/revogrid-column-numeral'; 

const numericPlugin = { 
    'numeric': new NumberColumnType('0,0'),
    'decimal': new NumberColumnType('0,0.[00]')
}

export default function RankTable(props) {
    defineCustomElements();

    const buildColumns = (scoringMethod) => {
        const vertColProps =({prop, model, data, column}) => {
            return {
                class: {
                    'vertical': true
                }
            }
        }
        const cellProps = ({prop, model, data, column}) => {
            return {
                style: {
                    "text-align": "center",
                }
            }
        }
        let cols = [
                {name:"Rank", prop:"rank", sortable: true, columnProperties: vertColProps, cellProperties: cellProps, columnType: "numeric", cellCompare: (prop, a, b) => { return a.rank - b.rank },readonly: (data) => {return true}}, 
                {
                    name:"Team #", prop:"team",  columnProperties: vertColProps, sortable: true, readonly: (data) => {return true},
                    cellProperties: ({prop, model, data, column}) => {
                        return {
                            style: {
                                "font-weight": 600
                            }
                        }
                    },
                    cellCompare: (prop, a, b) => { // Custom sorting logic
                        return a.team - b.team
                    },
                },
                {name:"Skip's name", prop:"skip", size: 100, sortable: true, readonly: (data) => {return true}, cellProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "text-align": "left",
                            "padding": "5px"
                        }
                    }
                }}
        ]
        
        const endsCol = {name: "Ends", prop:`ends`, sortable: true, columnProperties: vertColProps, cellProperties: cellProps, columnType: "numeric",  cellCompare: (prop, a, b) => { return a.ends - b.ends }, readonly: (data) => {return true}}
        const shotsForCol = {name: "For", prop:`for`, sortable: true, columnProperties: vertColProps, cellProperties: cellProps, columnType: "numeric",  cellCompare: (prop, a, b) => { return a.for - b.for }, readonly: (data) => {return true}}
        const shotsAgainstCol = {name: "Against", prop:`against`, sortable: true, columnProperties: vertColProps, cellProperties: cellProps, columnType: "numeric", cellCompare: (prop, a, b) => { return a.against - b.against }, readonly: (data) => {return true}}
        cols.push(
                {name:"Points", prop:"points", sortable: true, columnProperties: vertColProps, cellProperties: cellProps, columnType: "decimal", cellCompare: (prop, a, b) => { return a.points - b.points }, readonly: (data) => {return true}},
                ...(scoringMethod == 1 ?  [endsCol] : []),
                {name:"Margin", prop:"margin", sortable: true, columnProperties: vertColProps, cellProperties: cellProps, columnType: "numeric", cellCompare: (prop, a, b) => { return a.margin - b.margin }, readonly: (data) => {return true}},
                ...(scoringMethod == 0 ?  [endsCol] : []),
                ...(scoringMethod == 2 ?  [shotsForCol, shotsAgainstCol] : [])
        )

        return cols
    }

    const refreshData = () => {
        setSource(props.rankData.map((d) => {
            d.id = d.rank
            return d
        }))
    }

    useEffect(() => {
        setColumns(buildColumns(props.scoringMethod))
        refreshData()
    }, []);

    useEffect(() => {
        setColumns(buildColumns(props.scoringMethod))
        refreshData()
    }, [props.rankData]);

    useEffect(() => {
        setColumns(buildColumns(props.scoringMethod))
        refreshData()
    }, [props.scoringMethod]);

    const [columns, setColumns] = useState([])
    const [source, setSource] = useState([])

    return (
        <>
            <RevoGrid
                style={{
                    height: 61 + (props.rankData.length * 35),
                    minHeight: 61 + (props.rankData.length * 35),
                    maxHeight: "60vh",
                    justifyContent: 'center',
                }}
                columns={columns}
                source={source}
                stretch={false}
                colSize={35}
                rowSize={35}
                columnTypes={numericPlugin}
                hideAttribution={true}
            />  
        </>
    );
}

