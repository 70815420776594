import React from "react";
import { useState, useEffect } from "react";
import { defineCustomElements } from "@revolist/revogrid/loader"; // webcomponent definition loader 
import { RevoGrid } from "@revolist/react-datagrid";
import "../revogrid.css"
import { buildTable } from "./DrawTable";
import NumberColumnType from '@revolist/revogrid-column-numeral'; 

const numericPlugin = { 
    'numeric': new NumberColumnType('0,0')
}

export default function RinkPlaysTable(props) {

    function buildRinkCountsTable(numRinks, tableData) {
        let rows = []
        let min = Number.MAX_SAFE_INTEGER
        let max = 0
        for (let i=0; i<tableData.length; i++) {
            const team = i+1
            rows.push({team: team})
            let rinkArray = Object.keys(tableData[i])
                .filter((key) => key.startsWith('rink-'))
                .map((key) => {
                    return tableData[i][key]
                })
            for (let rink=1; rink<=numRinks; rink++) {
                let count = rinkArray.filter((e) => { 
                    return e == rink
                }).length
                rows[i][`rinkcount-${rink}`] = count
                if (count > max) { max = count }
                else if (count < min) { min = count }
            }
        }
        return { rows: rows, minValue: min, maxValue: max }
    }

    function buildColumns(numRinks, minValue, maxValue) {
        let cols = [
            {name: "", prop:"empty", columnProperties: ({prop, model, data, column}) => {
                return {
                    style: {
                        "background": "#ECF8ED"
                    }
                }}, children: [
                {name: "Team", prop: "team", sortable: false, pin: 'colPinStart', columnType: "numeric", columnProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "background": "#ECF8ED"
                        },
                        class: {
                            'vertical': true
                        }
                    }
                },
                cellProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "background": "#ECF8ED",
                            "padding-top": "0px",
                            "font-size": "100%;"
                        }
                    }
                },}, 
            ]},
            {name: "Rink", children:[], columnProperties: ({prop, model, data, column}) => {
                return {
                    style: {
                        "text-align": "left",
                        "padding": "0px 5px",
                    }
                }
            }}
        ]

        for (let i=1; i<=numRinks; i++) {
            cols[1].children.push({name:i, prop:`rinkcount-${i}`, sortable:false, cellProperties: ({prop, model, data, column, rowIndex}) => {
                const value = data[rowIndex][prop]

                function heatMapColorforValue(value) {
                    var h = (1.0 - value) * 60
                    var l = 70 + (1.0 - value) * 30
                    const hsl = "hsl(" + h + ", 100%, " + l.toString() + "%)"
                    return hsl
                }

                return {
                    style: {
                        "background": heatMapColorforValue((value - minValue) * (0.3 / (maxValue - minValue))),
                        "padding-top": "0px",
                        "font-size": "100%;"
                    }
                }
            },})
        }
        return cols
    }
    
    defineCustomElements();

    useEffect(() => {
        const data = buildRinkCountsTable(props.numRinks, buildTable(props.numTeams, props.rounds))
        const columns = buildColumns(props.numRinks, data.minValue, data.maxValue)
        setColumns(columns)
        setSource(data.rows)
    }, []);

    const [columns, setColumns] = useState([]);
    const [source, setSource] = useState([]);
    
    return (
        <>    
            <RevoGrid
                style={{
                    justifyContent: 'center',
                    height: 91.5 + (props.numTeams * 27),
                    minHeight: 91.5 + (props.numTeams * 27),
                    maxHeight: "300px",
                }}           
                columns={columns}
                source={source}
                stretch={false}
                colSize={27}
                readonly={true}
                hideAttribution={true}
                canFocus={false}
            />
        </>
    );
}

