import { React } from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import RinkPlaysTable from "../components/RinkPlaysTable";
import MatchupsTable from "../components/MatchupsTable";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

export default function StatisticsTab(props) {
  const itemData = props.itemData;
  const classes = useStyles();

  return (
    <Grid container={true} direction={"column"} spacing={9}>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={6}>
            <Box display="flex" style={{ justifyContent: "right" }}>
              <Card>
                <MatchupsTable
                  numTeams={itemData.teams}
                  rounds={itemData.rounds}
                />
              </Card>
            </Box>
          </Grid>
          <Grid item={true} xs={6}>
            <Box>
              <Typography variant="h6" paragraph={true}>
                <strong>Match-ups</strong>
              </Typography>
              <Typography paragraph={true}>
                The number of times each team plays every other team, with total
                number of games per team.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={6}>
            <Box display="flex" style={{ justifyContent: "right" }}>
              <Box>
                <Typography variant="h6" paragraph={true}>
                  <strong>Rink plays</strong>
                </Typography>
                <Typography paragraph={true}>
                  The number of times teams play on each rink.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item={true} xs={6}>
            <Box display="flex" style={{ justifyContent: "left" }}>
              <Card>
                <RinkPlaysTable
                  numTeams={itemData.teams}
                  numRinks={itemData.rinks}
                  rounds={itemData.rounds}
                />
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
