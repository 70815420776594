import { React, useState } from "react";
import { Box, Card, colors, InputLabel } from "@material-ui/core";
import DrawTable from "../components/DrawTable";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  makeStyles,
} from "@material-ui/core/styles";
import {} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import LinkMui from "@material-ui/core/Link";
import RankTable from "./RankTable";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  caption: {
    color: colors.grey["600"],
  }
}));

export default function ScoreTab(props) {
  const classes = useStyles()
  const itemData = props.itemData
  const scoringMethod = itemData.scoringMethod

  const handleScoringMethodChange = (event) => {
    props.scoringMethodUpdated(event.target.value);
  };

  const [rankData, setRankData] = useState([])

  return (
    <Grid container={true} spacing={4}>
      <Grid item={true} xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12} md={6}>
                <FormControl style={{ marginBottom: "30px" }}>
                  <InputLabel id="scoring-method-select-label">
                    Rank by
                  </InputLabel>
                  <Select
                    labelId="scoring-method-select-label"
                    id="scoring-method-select"
                    value={scoringMethod}
                    label="Rank by"
                    onChange={handleScoringMethodChange}
                  >
                    <MenuItem value={0}>Points, margin, ends</MenuItem>
                    <MenuItem value={1}>Points, ends, margin</MenuItem>
                    <MenuItem value={2}>
                      Points, margin, shots for, shots against
                    </MenuItem>
                  </Select>
                </FormControl>
                {scoringMethod === 0 && (
                  <Typography paragraph={true}>
                    Standings will be ordered by number of points. Tiebreakers
                    will be shots difference, followed by ends won.
                  </Typography>
                )}
                {scoringMethod === 1 && (
                  <Typography paragraph={true}>
                    Standings will be ordered by number of points. Tiebreakers
                    will be ends won, followed by shots difference.
                  </Typography>
                )}
                {scoringMethod === 2 && (
                  <Typography paragraph={true}>
                    Standings will be ordered by number of points. Tiebreakers
                    will be shots difference, followed by shots for, then shots
                    against.
                  </Typography>
                )}
                <Typography variant="caption" paragraph={true} className={classes.caption}>
                  Don't see your scoring method here?
                  <LinkMui component={Link} to="/contact">
                  &nbsp;Suggest a new one.
                  </LinkMui>
                </Typography>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Box
                  component="fieldset"
                  sx={{ padding: 20, borderColor: "success.main" }}
                  borderRadius={5}
                  border={1}
                >
                  <legend>Points</legend>
                  <Box>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={3}>
                        <TextField
                          variant="outlined"
                          type="number"
                          label="Win"
                          name="numPointsPerWin"
                          defaultValue={itemData.points.win}
                          onChange={(event) => { props.pointsWinChanged(event.target.value) }}
                          fullWidth={false}
                          autoFocus={false}
                        />
                      </Grid>
                      <Grid item={true} xs={3}>
                        <TextField
                          variant="outlined"
                          type="number"
                          label="Draw"
                          name="numPointsPerDraw"
                          defaultValue={itemData.points.draw}
                          onChange={(event) => { props.pointsDrawChanged(event.target.value) }}
                          fullWidth={false}
                          autoFocus={false}
                        />
                      </Grid>
                      <Grid item={true} xs={3}>
                        <TextField
                          variant="outlined"
                          type="number"
                          label="Loss"
                          name="numPointsPerLoss"
                          defaultValue={itemData.points.loss}
                          onChange={(event) => { props.pointsLossChanged(event.target.value) }}
                          fullWidth={false}
                          autoFocus={false}
                        />
                      </Grid>
                      <Grid item={true} xs={3}>
                        <TextField
                          variant="outlined"
                          type="number"
                          label="Ends"
                          name="numPointsPerEndWon"
                          defaultValue={itemData.points.ends}
                          onChange={(event) => { props.pointsEndsChanged(event.target.value) }}
                          fullWidth={false}
                          autoFocus={false}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12}>
        <Box
          display="flex"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Card>
            <DrawTable
              numTeams={itemData.teams}
              rounds={itemData.rounds}
              showScores={true}
              scoringMethod={scoringMethod}
              skipNameUpdated={props.skipNameUpdated}
              endsScoreUpdated={props.endsScoreUpdated}
              homeScoreUpdated={props.homeScoreUpdated}
              awayScoreUpdated={props.awayScoreUpdated}
              rankDataUpdated={setRankData}
              teamInfo={itemData.teamInfo}
              scores={itemData.scores}
              points={itemData.points}
            />
          </Card>
        </Box>
      </Grid>
      <Grid item={true} xs={12}>
        <Box
          display="flex"
          style={{ alignItems: "center", justifyContent: "left" }}
        >
          <Card>
            <Typography variant="h6" paragraph={true}>
                <strong>Standings</strong>
            </Typography>
            <RankTable
              rankData={rankData}
              scoringMethod={scoringMethod}
            />
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
