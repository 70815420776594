import React, { useReducer } from "react";
import { useState } from "react";
import { defineCustomElements } from "@revolist/revogrid/loader"; // webcomponent definition loader 
import { RevoGrid } from "@revolist/react-datagrid";
import "../revogrid.css"
import { useEffect } from "react";
import NumberColumnType from '@revolist/revogrid-column-numeral'; 

const numericPlugin = { 
    'numeric': new NumberColumnType('0,0'),
    'decimal': new NumberColumnType('0,0.[00]')
}

export default function DrawTable(props) {
    defineCustomElements();

    const [showScores, setShowScores] = useState(props.showScores)
    let isPhone = window.screen.width < 500

    const buildColumns = (numRounds, showScores, scoringMethod) => {
        const standingsColProps =({prop, model, data, column}) => {
            return {
                class: {
                    'vertical': true
                }
            }
        }
        let cols = [
            {
                name:"Team", children: [
                    {
                        name:"#", prop:"team", pin: 'colPinStart', sortable: true, readonly: (data) => {return true},
                        cellProperties: ({prop, model, data, column}) => {
                            return {
                                style: {
                                    "font-weight": 600
                                }
                            }
                        },
                        cellCompare: (prop, a, b) => { return a.team - b.team }
                    },
                    {name: isPhone ? "Skip" : "Skip's name", prop:"skip", pin: 'colPinStart', size: isPhone ? 75 : 100, sortable: true, readonly: (data) => {return false}, cellCompare: (prop, a, b) => { if (!b.skip) return -1; if (!a.skip) return 1; return (a.skip.toLowerCase()).localeCompare(b.skip.toLowerCase()) }, cellProperties: ({prop, model, data, column}) => {
                        return {
                            style: {
                                "text-align": "left",
                                "padding": "5px"
                            }
                        }
                    }}
                ],
            },
            {
                name: "Round",
                readonly: (data) => {return true}, 
                children: [], 
                columnProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "text-align": "left",
                            "padding": "0px 5px",
                        }
                    }
                },
            },
        ]
        
        for (let i=1; i<=numRounds; i++) {
            const disabledCellProps = ({prop, model, data, column}) => {
                if (model[`vs-${i}`] == "B" || model[`rink-${i}`] == "B") {
                    return {
                        style: {
                            background: "#fff6fa"
                        }
                    }    
                }
                return null
            }

            let children = [
                {
                    name:"vs", 
                    readonly: (data) => {return true},
                    prop:`vs-${i}`, 
                    sortable: false,
                    columnProperties: ({prop, model, data, column}) => {
                        return {
                            style: {
                                "background": "#fbdb4b44"
                            },
                            class: {
                                'vertical': true
                            }
                        }
                    },
                    cellProperties: ({prop, model, data, column}) => {
                        let disabledStyle = disabledCellProps({prop, model, data, column})
                        if (disabledStyle != null) {
                            return disabledStyle
                        }
                        return {
                            style: {
                                "background": "#fbdb4b44",
                            }
                        }
                    }
                },
                {
                    name:"Rink", 
                    readonly: (data) => {return true},
                    sortable: true,
                    prop:`rink-${i}`,
                    columnProperties: ({prop, model, data, column}) => {
                        return {
                            class: {
                                'vertical': true
                            }
                        }
                    },
                    cellProperties: disabledCellProps,
                    cellCompare: (prop, a, b) => { return a[`rink-${i}`] - b[`rink-${i}`] }
                },
            ]
            if (showScores) {
                const colProps = ({prop, model, data, column}) => {
                    return {
                        class: {
                            'vertical': true
                        }
                    }
                }
                const cellProps = ({prop, model, data, column}) => {
                    let disabledStyle = disabledCellProps({prop, model, data, column})
                    if (disabledStyle != null) {
                        return disabledStyle
                    }
                    return {
                        style: {
                        }
                    }
                }
                let readonly = (data) => {
                    let disabledStyle = disabledCellProps(data)
                    return (disabledStyle != null)
                }
                
                children.push(
                    //{name:"Score", children: [
                        {name: "For", prop:`for-${i}`, columnProperties: colProps, columnType: "numeric", cellProperties: cellProps, readonly: readonly, cellCompare: (prop, a, b) => { return a[`for-${i}`] - b[`for-${i}`] }},
                        {name: "Against", prop:`against-${i}`, columnProperties: colProps, columnType: "numeric", cellProperties: cellProps, readonly: readonly, cellCompare: (prop, a, b) => { return a[`against-${i}`] - b[`against-${i}`] }},
                        ...(scoringMethod == 0 || scoringMethod == 1 ? 
                            [{name: "Ends", prop:`ends-${i}`, columnProperties: colProps, columnType: "numeric", cellProperties: cellProps, readonly: readonly, cellCompare: (prop, a, b) => { return a[`ends-${i}`] - b[`ends-${i}`] }}]
                            : []
                        ),
                        {name: "Margin", prop:`margin-${i}`, columnProperties: colProps, columnType: "numeric", cellProperties: ({prop, model, data, column}) => {
                            let disabledStyle = disabledCellProps({prop, model, data, column})
                            if (disabledStyle != null) {
                                return disabledStyle
                            }
    
                            let color = null
                            const value = model[prop]
                            if (value > 0) {
                                color = "#ECF8ED"
                            } else if (value < 0) {
                                color = "#FFBCAE77"
                            }
                            return {
                                style: {
                                    "background": color
                                }
                            }
                        }, readonly: (data) => {return true}, cellCompare: (prop, a, b) => { return a[`margin-${i}`] - b[`margin-${i}`] }},
                    //]}
                )
            }
            cols[1].children.push({ name:`${i}`, children: children })
        }
        
        if (showScores && !isPhone) {
            const cellProps = ({prop, model, data, column}) => {
                return {
                    style: {
                        "text-align": "center",
                    }
                }
            }
            const endsCol = {name: "Ends", prop:`ends`, pin: 'colPinEnd', sortable: true, columnProperties: standingsColProps, cellProperties: cellProps, columnType: "numeric", readonly: (data) => {return true}, cellCompare: (prop, a, b) => { return a.ends - b.ends }}
            const shotsForCol = {name: "For", prop:`for`, pin: 'colPinEnd', sortable: true, columnProperties: standingsColProps, cellProperties: cellProps, columnType: "numeric", readonly: (data) => {return true}, cellCompare: (prop, a, b) => { return a.for - b.for }}
            const shotsAgainstCol = {name: "Against", prop:`against`, pin: 'colPinEnd', sortable: true, columnProperties: standingsColProps, cellProperties: cellProps, columnType: "numeric", readonly: (data) => {return true}, cellCompare: (prop, a, b) => { return a.against - b.against }}
            cols.push({
                name:"Standings",  children: [
                    {name:"Points", prop:"points", pin: 'colPinEnd', sortable: true, columnProperties: standingsColProps, cellProperties: cellProps, columnType: "decimal", readonly: (data) => {return true}, cellCompare: (prop, a, b) => { return a.points - b.points }},
                    ...(scoringMethod == 1 ?  [endsCol] : []),
                    {name:"Margin", prop:"margin", pin: 'colPinEnd', sortable: true, columnProperties: standingsColProps, cellProperties: cellProps, columnType: "numeric", readonly: (data) => {return true}, cellCompare: (prop, a, b) => { return a.margin - b.margin }},
                    ...(scoringMethod == 0 ?  [endsCol] : []),
                    ...(scoringMethod == 2 ?  [shotsForCol, shotsAgainstCol] : []),
                    {name:"Rank", prop:"rank", pin: 'colPinEnd', sortable: true, columnProperties: standingsColProps, cellProperties: cellProps, columnType: "numeric", readonly: (data) => {return true}, cellCompare: (prop, a, b) => { return a.rank - b.rank }},
                ],
            })
        }
        return cols
    }

    const fillRowCalculations = (team, aRound) => {
        setSource(s => {
            // Calculate margin
            if (aRound != null) {
                s[team-1][`margin-${aRound}`] = s[team-1][`for-${aRound}`] - s[team-1][`against-${aRound}`]
            }

            // Calculate totals
            let endsTotal = 0
            let marginTotal = 0
            let pointsTotal = 0
            let shotsForTotal = 0
            let shotsAgainstTotal = 0
            for (let round=1; round<=props.rounds.length; round++) {

                if (aRound == null) {
                    s[team-1][`margin-${round}`] = s[team-1][`for-${round}`] - s[team-1][`against-${round}`]
                }

                const ends = parseInt(s[team-1][`ends-${round}`])
                if (!isNaN(ends)) {
                    endsTotal += ends
                }
                const margin = parseInt(s[team-1][`margin-${round}`])
                if (!isNaN(margin)) {
                    marginTotal += margin
                    let points = 0
                    if (margin > 0) {
                        points = parseFloat(props.points.win)
                    } else if (margin < 0) {
                        points = parseFloat(props.points.loss)
                    } else {
                        points = parseFloat(props.points.draw)
                    }
                    
                    pointsTotal += points + (endsTotal * props.points.ends)
                }
                const shotsFor = parseInt(s[team-1][`for-${round}`])
                if (!isNaN(shotsFor)) {
                    shotsForTotal += shotsFor
                }
                const shotsAgainst = parseInt(s[team-1][`against-${round}`])
                if (!isNaN(shotsAgainst)) {
                    shotsAgainstTotal += shotsAgainst
                }
            }
            s[team-1].ends = endsTotal
            s[team-1].margin = marginTotal
            s[team-1].points = pointsTotal
            s[team-1].for = shotsForTotal
            s[team-1].against = shotsAgainstTotal
            
            return s
        })
    }

    const fillRankingsColumn = () => {
        const sortFunction = (a, b) => {
            if (props.scoringMethod == 0) {
                const pointsDiff = b.points - a.points
                if (pointsDiff != 0) {
                    return pointsDiff
                }
                const marginDiff = b.margin - a.margin
                if (marginDiff != 0) {
                    return marginDiff
                }
                const endsDiff = b.ends - a.ends
                if (endsDiff != 0) {
                    return endsDiff
                }
            } else if (props.scoringMethod == 1) {
                const pointsDiff = b.points - a.points
                if (pointsDiff != 0) {
                    return pointsDiff
                }
                const endsDiff = b.ends - a.ends
                if (endsDiff != 0) {
                    return endsDiff
                }
                const marginDiff = b.margin - a.margin
                if (marginDiff != 0) {
                    return marginDiff
                }
            } else if (props.scoringMethod == 2) {                
                const pointsDiff = b.points - a.points
                if (pointsDiff != 0) {
                    return pointsDiff
                }
                const marginDiff = b.margin - a.margin
                if (marginDiff != 0) {
                    return marginDiff
                }
                const shotsForDiff = b.for - a.for
                if (shotsForDiff != 0) {
                    return shotsForDiff
                }
                const shotsAgainstDiff = b.against - a.against
                if (shotsAgainstDiff != 0) {
                    return shotsAgainstDiff
                }
            }
            return 0 // A draw
        }

        setSource(prevSource => {
            const sorted = [...prevSource].sort(sortFunction)
            let rank = 1
            for (let i=0; i<sorted.length; i++) {
                if (i > 0 && sortFunction(sorted[i], sorted[i-1]) != 0) {
                    rank++
                }
                prevSource[sorted[i].id - 1].rank = rank
            }

            return prevSource
        })
    }
    
    useEffect(() => {
        const columns = buildColumns(props.rounds.length, showScores, props.scoringMethod)
        setColumns(columns)
        const source = buildTable(props.numTeams, props.rounds, props.teamInfo, props.scores)
        setSource(source)    
        if (showScores) {
            for (let i=0; i<source.length; i++) {
                fillRowCalculations(i+1, null)
            }
            fillRankingsColumn()
        }
    }, []);

    useEffect(() => {
        const columns = buildColumns(props.rounds.length, showScores, props.scoringMethod)
        setColumns(columns)
    }, [props.scoringMethod]);

    const [columns, setColumns] = useState([])
    const [source, setSource] = useState([])

    useEffect(() => {
        if (showScores && source.length > 0) {
            
            // WHY NEEDED??
            // For some reason here RevoGrid isn't refreshing the source. Verified the component receives the new data
            // but it seems to want a new array ref for the columns
            const clone = (columns) => columns.map(item => Array.isArray(item) ? clone(item) : item);
            setColumns(clone)
            ///
            
            for (let i=0; i<source.length; i++) {
                fillRowCalculations(i+1, null)
            }
            fillRankingsColumn()
        }
    }, [props.points]);

    useEffect(() => {
        let rankData = source.map((item) => {
            return {rank: item.rank, team: item.team, skip: item.skip, points: item.points, margin: item.margin, for: item.for, against: item.against, ends: item.ends}
        }).sort((a, b) => {
            return a.rank - b.rank
        })

        props.rankDataUpdated(rankData)
    }, [JSON.stringify(source)]);


    const tableEdit = (e) => {
        const team = e.detail.model.team
        
        if (e.detail.prop == "skip") {
            props.skipNameUpdated(team, e.detail.model.skip)   
        } else if (e.detail.prop.startsWith("ends-")) {
            let round = parseInt(e.detail.prop.replace(/^ends-/, ''))
            props.endsScoreUpdated(team, round, parseInt(e.detail.model[e.detail.prop]))
            fillRowCalculations(team, round)
        } else if (e.detail.prop.startsWith("against-")) {
            const round = parseInt(e.detail.prop.replace(/^against-/, ''))
            const game = parseInt(e.detail.model[`game-${round}`])
            const side = e.detail.model[`side-${round}`]
            const shots = parseInt(e.detail.model[e.detail.prop])
            
            if (side == "home") {
                props.awayScoreUpdated(game, round, shots)
            } else {
                props.homeScoreUpdated(game, round, shots)
            }

            fillRowCalculations(team, round)
            let otherTeam = e.detail.model[`vs-${round}`]
            setSource(prevSource => {
                prevSource[otherTeam-1][`for-${round}`] = shots
                return prevSource
            })
            fillRowCalculations(otherTeam, round)
        } else if (e.detail.prop.startsWith("for-")) {
            let round = parseInt(e.detail.prop.replace(/^for-/, ''))
            let game = parseInt(e.detail.model[`game-${round}`])
            let side = e.detail.model[`side-${round}`]
            let shots = parseInt(e.detail.model[e.detail.prop])
            
            if (side == "home") {
                props.homeScoreUpdated(game, round, shots)
            } else {
                props.awayScoreUpdated(game, round, shots)
            }

            fillRowCalculations(team, round)
            let otherTeam = e.detail.model[`vs-${round}`]
            setSource(prevSource => {
                prevSource[otherTeam-1][`against-${round}`] = shots
                return prevSource
            })
            fillRowCalculations(otherTeam, round)        
        }

        fillRankingsColumn()
    }
    
    return (
        <>
            <RevoGrid
                style={{
                    height: 122 + (props.numTeams * 35),
                    maxHeight: "60vh",
                    minHeight: 122 + (props.numTeams * 35),
                    justifyContent: 'center',
                }}
                columns={columns}
                source={source}
                stretch={false}
                colSize={35}
                rowSize={35}
                columnTypes={numericPlugin}
                onAfteredit={tableEdit}
                applyOnClose={true}
                hideAttribution={true}
            />  
        </>
    );
}

export function buildTable(numTeams, roundRobin, teamInfo, scores) {
    let tableData = []
    for (let i=1; i<=numTeams; i++) {
        tableData.push({id: i, team: i, skip: teamInfo?.[i]})
    }

    for (let roundIndex=1; roundIndex<=roundRobin.length; roundIndex++) {
        let round = roundRobin[roundIndex - 1]
        for (let gameIndex=0; gameIndex<round.games.length; gameIndex++) {
            const game = round.games[gameIndex]
            let pair = game.pair
            if (pair != null) {
                let team = pair[0]
                if (team !== null) {
                    tableData[team - 1][`vs-${roundIndex}`] = pair[1] || "B"
                    tableData[team - 1][`rink-${roundIndex}`] = game.rink
                    tableData[team - 1][`game-${roundIndex}`] = gameIndex
                    tableData[team - 1][`side-${roundIndex}`] = "home"
                }
                
                let opponent = pair[1]
                if (opponent !== null) {
                    tableData[opponent - 1][`vs-${roundIndex}`] = pair[0] || "B"
                    tableData[opponent - 1][`rink-${roundIndex}`] = game.rink
                    tableData[opponent - 1][`game-${roundIndex}`] = gameIndex
                    tableData[opponent - 1][`side-${roundIndex}`] = "away"
                }
                
                let scoresRound = scores?.[`round-${roundIndex}`]
                if (scoresRound != null) {
                    let scoreGame = scoresRound?.[`game-${gameIndex}`]
                    if (scoreGame != null) {
                        tableData[team - 1][`for-${roundIndex}`] = scoreGame["shots-home"] 
                        tableData[team - 1][`against-${roundIndex}`] = scoreGame["shots-away"]
                        tableData[team - 1][`margin-${roundIndex}`] = scoreGame["shots-home"] - scoreGame["shots-away"] 
                        tableData[opponent - 1][`for-${roundIndex}`] = scoreGame["shots-away"] 
                        tableData[opponent  - 1][`against-${roundIndex}`] = scoreGame["shots-home"] 
                        tableData[opponent - 1][`margin-${roundIndex}`] = scoreGame["shots-away"] - scoreGame["shots-home"] 
                    }
                }

                // TODO: consider updating all margins and standings in one pass, row by row
            }
        }
    }

    for (let i=0; i<tableData.length; i++) {
        for (let j=0; j<roundRobin.length; j++) {
            if (tableData[i][`vs-${j+1}`] != "B") {
                tableData[i][`rink-${j+1}`] ||= "B"
            }

            let scoresRound = scores?.[`round-${j+1}`]
            let scoreTeam = scoresRound?.[`team-${i+1}`]
            if (scoreTeam != null) {
                tableData[i][`ends-${j+1}`] = parseInt(scoreTeam["ends-won"])
            }
        }
    }

    return tableData
}