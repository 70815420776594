import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { useItem, updateItem, createItem } from "./../util/db";
import { Tabs, Tab } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function EditItemModal(props) {
  const classes = useStyles();

  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };


  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const { data: itemData, status: itemStatus } = useItem(props.id);
  
  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && itemStatus !== "success") {
    return null;
  }

//  setTabIndex(itemData?.gamesTab || 0)

  const onSubmit = (data) => {
    setPending(true);

    const input = {
      teams: Array.from({length: data.teams}, (_, i) => i + 1),
      rotations: data?.numRoundRobins ? data.numRoundRobins : 1,
      rinks: Array.from({length: data.rinks}, (_, i) => i + 1),
      maxGamesPerTeam: data?.numGamesPerTeam ? data.numGamesPerTeam : null
    }

    fetch('/api/roundrobin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input)
    })
    .then((response) => response.json()) 
    .then((rounds) => {
      data.rounds = rounds
      data.points = {
        win: 1,
        draw: 0.5,
        loss: 0,
        ends: 0
      }
      data.scoringMethod = 0
      
      const query = props.id
        ? updateItem(props.id, data)
        : createItem({ owner: auth.user.uid, ...data });

        query
          .then(() => {
            // Let parent know we're done so they can hide modal
            props.onDone();
          })
          .catch((error) => {
            // Hide pending indicator
            setPending(false);
            // Show error alert message
            setFormAlert({
              type: "error",
              message: error.message,
        });
      });  
    })
  };

  return (
    <Dialog open={true} onClose={props.onDone}>
      <DialogTitle>
        {props.id && <>Edit</>}
        {!props.id && <>New</>}
        {` `}Tournament
      </DialogTitle>
      <DialogContent className={classes.content}>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label="Tournament name"
                name="name"
                defaultValue={itemData && itemData.name}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                autoFocus={true}
                inputRef={register({
                  required: "Please enter a name",
                })}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <TextField
                variant="outlined"
                type="number"
                label="Number of teams"
                name="teams"
                defaultValue={(itemData && itemData.teams) || 6}
                error={errors.teams ? true : false}
                helperText={errors.teams && errors.teams.message}
                fullWidth={false}
                autoFocus={false}
                inputRef={register({
                  required: "Please enter number of teams",
                })}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <TextField
                variant="outlined"
                type="number"
                label="Available rinks"
                name="rinks"
                defaultValue={(itemData && itemData.rinks) || 6}
                error={errors.rinks ? true : false}
                helperText={errors.rinks && errors.rinks.message}
                fullWidth={false}
                autoFocus={false}
                inputRef={register({
                  required: "Please enter number of rinks",
                })}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Box component="fieldset" sx={{ padding: 20, borderColor: "success.main" }} borderRadius={5} border={1}>
                <legend>Number of games</legend>
                <Box sx={{ paddingBottom: 30 }}>
                  <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Round robin"  />
                    <Tab label="Custom"  />
                  </Tabs>
                </Box>
                <Box>
                  {tabIndex === 0 && (
                    <Box>
                      <Box sx={{ paddingBottom: 30 }}>
                        <Typography paragraph={true}>In a round robin, each team will play every other team once. You can choose to play multiple round robins.</Typography>
                      </Box>
                      <Grid item={true} xs={4}>
                        <TextField
                          variant="outlined"
                          type="number"
                          label="Full round robins"
                          name="numRoundRobins"
                          defaultValue={(itemData && itemData.numRoundRobins) || 1}
                          error={errors.numRoundRobins ? true : false}
                          helperText={errors.numRoundRobins && errors.numRoundRobins.message}
                          fullWidth={false}
                          autoFocus={false}
                          inputRef={register({
                            required: "Please enter number of full round robins",
                          })}
                        />
                      </Grid>
                    </Box>
                  )}
                  {tabIndex === 1 && (
                    <Box>
                      <Box sx={{ paddingBottom: 30 }}>
                        <Typography paragraph={true}>Choose how many games you would like each team to play.</Typography>
                      </Box>
                      <Grid item={true} xs={4}>
                        <TextField
                          variant="outlined"
                          type="number"
                          label="Games per team"
                          name="numGamesPerTeam"
                          defaultValue={(itemData && itemData.numGamesPerTeam) || 3}
                          error={errors.numGamesPerTeam ? true : false}
                          helperText={errors.numGamesPerTeam && errors.numGamesPerTeam.message}
                          fullWidth={false}
                          autoFocus={false}
                          inputRef={register({
                            required: "Please enter number of games per team",
                          })}
                        />
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span>Save</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditItemModal;
