import React from "react";
import { useState, useEffect } from "react";
import { defineCustomElements } from "@revolist/revogrid/loader"; // webcomponent definition loader 
import { RevoGrid } from "@revolist/react-datagrid";
import "../revogrid.css"
import { buildTable } from "./DrawTable";
import NumberColumnType from '@revolist/revogrid-column-numeral'; 

const numericPlugin = { 
    'numeric': new NumberColumnType('0,0')
}

export default function MatchupsTable(props) {

    function buildMatchupsTable(tableData) {
        let rows = []
        let min = Number.MAX_SAFE_INTEGER
        let max = 0
        for (let i=0; i<tableData.length; i++) {
            const team = i+1
            let total = 0
            rows.push({team: team})
            let vsArray = Object.keys(tableData[i])
                .filter((key) => key.startsWith('vs-'))
                .map((key) => {
                    return tableData[i][key]
                })
            for (let opponent=1; opponent<=tableData.length; opponent++) {
                if (opponent == team) {
                    rows[i][`match-${opponent}`] = "-"    
                    min = 0
                    continue
                }
                let count = vsArray.filter((e) => { 
                    return e == opponent
                }).length
                rows[i][`match-${opponent}`] = count
                if (count > max) { max = count }
                else if (count < min) { min = count }
                total += count
            }
            rows[i]["match-total"] = total
        }
        return {
            rows: rows,
            min: min,
            max: max
        }
    }

    function buildColumns(numTeams, minValue, maxValue) {
        let cols = [
            {name: "", prop:"empty", columnProperties: ({prop, model, data, column}) => {
                return {
                    style: {
                        "background": "#ECF8ED"
                    }
                }}, children: [
                {name: "Team", prop:"team", pin: 'colPinStart', sortable: true, columnType: "numeric", columnProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "background": "#ECF8ED"
                        },
                        class: {
                            'vertical': true
                        }
                    }
                }, 
                cellCompare: (prop, a, b) => { // Custom sorting logic
                    return a.team - b.team
                },
                cellProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "background": "#ECF8ED",
                            "padding-top": "0px",
                            "font-size": "100%;"
                        }
                    }
                },}
            ]},                
            {name: "vs Team", prop: "vs", children: [], columnType: "numeric", columnProperties: ({prop, model, data, column}) => {
                return {
                    style: {
                        "text-align": "left",
                        "padding": "0px 5px",
                    }
                }
            }},
            {name:"", prop: "empty", columnProperties: ({prop, model, data, column}) => {
                return {
                    style: {
                        "background": "#ECF8ED"
                    }
                }}, children: [
                {name: "Total", prop: "match-total", pin: 'colPinEnd', sortable: true, columnType: "numeric", columnProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "background": "#ECF8ED"
                        },
                        class: {
                            'vertical': true
                        }
                    }
                },
                cellProperties: ({prop, model, data, column}) => {
                    return {
                        style: {
                            "background": "#ECF8ED",
                            "padding-top": "0px",
                            "font-size": "100%;"
                        }
                    }
                },},
            ]} 
        ]
        for (let i=1; i<=numTeams; i++) {
            cols[1].children.push({name:i, prop:`match-${i}`, sortable: false,
                cellProperties: ({prop, model, data, column, rowIndex}) => {
                    const value = data[rowIndex][prop]
                    
                    function heatMapColorforValue(value) {
                        var h = (1.0 - value) * 60
                        var l = 70 + (1.0 - value) * 30
                        const hsl = "hsl(" + h + ", 100%, " + l.toString() + "%)"
                        return hsl
                    }
                    return {
                        style: {
                            "background": heatMapColorforValue((value - minValue) * (0.3 / (maxValue - minValue))),
                            "padding-top": "0px",
                            "font-size": "100%;"
                        }
                    }
                }
            })
        }
        return cols
    }

    defineCustomElements();

    useEffect(() => {
        const data = buildMatchupsTable(buildTable(props.numTeams, props.rounds))
        const columns = buildColumns(props.numTeams, data.min, data.max)
        setColumns(columns)
        setSource(data.rows)
    }, []);

    const [columns, setColumns] = useState([]);
    const [source, setSource] = useState([]);
    
    return (
        <>    
            <RevoGrid
                style={{
                    justifyContent: 'center',
                    height: 91.5 + (props.numTeams * 27),
                    minHeight: 91.5 + (props.numTeams * 27),
                    maxHeight: "300px",
                }}           
                columns={columns}
                source={source}
                stretch={false}
                colSize={27}
                readonly={true}
                hideAttribution={true}
                canFocus={false}
            />
        </>
    );
}

