import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { CenterFocusStrongTwoTone, AssessmentTwoTone, FavoriteTwoTone, StarsTwoTone, TodayTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Flexible draws",
      subtitle:
        "Teams automatically scheduled across any number of games and rinks.",
      icon: TodayTwoTone,
      iconColor: "secondary.main",
    },
    {
      title: "Scoring",
      subtitle:
        "Integrated scorekeeping based on your criteria.",
      icon: StarsTwoTone,
      iconColor: "secondary.main",
    },
    {
      title: "Fair rink allocation",
      subtitle:
        "Advanced rink allocation algorithm ensures no team is disadvantaged.",
      icon: CenterFocusStrongTwoTone,
      iconColor: "secondary.main",
    },
    {
      title: "Game reports",
      subtitle:
        "Detailed statistics ensuring a fair tournament.",
      icon: AssessmentTwoTone,
      iconColor: "secondary.main",
    },
    {
      title: "Free",
      subtitle:
        "Free for a limited time! Competitions created today are free forever.",
      icon: FavoriteTwoTone,
      iconColor: "secondary.main",
    }
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} justifyContent="center" spacing={7}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="4.5rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h5" gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
